<template>
	<tag :inf="inf" :internalUrl="internalUrl" :mapping="mapping" />
</template>

<script>
import tag from 'pages/securityNumTag'

export default {
	components: { tag },
	data() {
		return {
			inf: [
				{ title: 'No.', model: 'idx', size: 2 },
				{ title: '구분', key: 'typeCode', size: 6 },
				{ title: '전화번호', key: 'rn', size: 6 },
				{ title: '안심번호', key: 'vn', size: 6 },
				{ title: '등록일', key: 'createDt', size: 6 },
				{ title: '핫트아이디', key: 'hottId', size: 6 },
				{ title: '딜 링크', key: 'dealNum', size: 12, notCenter: 1, model: 'url' },
			],
			internalUrl: '/securityphoneSelect/securityphoneHistoryList',
		}
	},
	methods: {
		mapping(ths, res) {
			res.list = res.list.map(v => {
				v.typeCode = v.typeCode == 'Y' ? '등록' : '삭제'
				v.createDt = v.createDt.replace('T', ' ')
				v.dealNum = v.dealNum ? dealHost + '/' + v.dealNum : ''
				return v
			})
			ths.res = res
			return res
		},
	},
}
</script>
